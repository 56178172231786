@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');

@layer base {
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Montserrat',
      sans-serif;
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html {
    scroll-behavior: smooth !important;
    font-family    : 'Raleway', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply font-bold
  }

  h1 {
    @apply text-4xl sm:text-5xl md:text-6xl font-[Raleway]
  }

  h2 {
    @apply text-3xl sm:text-4xl
  }

  li {
    @apply cursor-pointer
  }

  button {}
}



.line-before-heading {
  display    : flex;
  align-items: center;
}

.line {

  border          : none;
  height          : 2px;
  background-color: black;
  margin-right    : 20px;
  margin-left    : 20px;
  width           : 35px;

  background-color: #788E9F80;
}

.vidd {
  display       : flex;
  flex-direction: row;
  
    align-self: flex-start;
    align-items: center;

}

.error-msg {
  direction: ltr;
  display: block;
  color: red;
  margin: 20px auto 0 auto;
  text-align: center;

  &::before {
    font-size: inherit;
    display: inline;
    content: '❌';
    margin-right: 7px;
  }
}

.success-msg {
  display: block;
  color: green;
  margin: 20px auto;
  text-align: center;

  &::before {
    display: inline;
    content: '✅';
    margin-right: 7px;
  }
}

@media screen and (max-width: 890px) {
  .parfast {
    position : relative;
    font-size: 44px;
  }
}

.btn-download {
  border-radius: 22px;
  font-size    : 13px;
  height       : 26px;
  padding      : 11px;
}

.btn-log {
  border-radius: 44px;
  width: 179px;
  height: 47px;
}

.descr2 {
  font-size     : 13px;
  padding-top   : 10px;
  padding-bottom: 10px;
}

.store-image {
  position: absolute;
  top: -390px;
  left: 432px;
  width: 62px;
  height: 62px;
  background: #FFFFFF;
  border-radius: 100%;
  padding: 18px;
  box-shadow: 0px 4px 4px #5f87a09c;
  opacity: 1;
}

.home-container {
  position: relative;
  padding-bottom: 100px;
}

.background-image {
  position           : absolute;
  bottom             : 0px;
  left               : 0;
  width              : 100%;
  height             : 100%;
  opacity            : 1;
  z-index            : 2;
  display: flex;
  align-items: flex-end;
  background-image: url("./assets/Bg2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; 
}

.english_upmob path{
  transform: translateY(100px)
}
.english_up path{
  transform: translateY(10px)
}

.arabic_down path{
  transform: translateY(30px)
}
.arabic_downmob path{
  transform: translateY(100px)
}

.wave-container{
  position: relative;
  z-index: 8;
  padding-top: -100px;
}

.par{
  font-size: 30px;

  width: 370px;
  padding: 30px;
  background: #FFFFFF;
  opacity: 1;
  line-height: 2rem;
  left:-55px;
}

.videoo{
  width: 100%;
  height: 475px;
}
.poster-image {
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.iframe-placeholder{
  width: 700px;
  height: 400px;
}

@media screen and ( max-width: 854px) {
  .image-circle {
    width          : 80px;
    height         : 80px;
    border-radius  : 50%;
    display        : flex;
    align-items    : center;
    justify-content: center;
    overflow       : hidden;
  }
}

@media screen and ( max-width: 854px) {

.videoo{
  width: 300px;
  height: 200px;
}
.poster-image {
  width: 300px;
  height: 200px;
}

.vidd{
  display: flex;
    align-items: center;
    align-self: auto;
}

.overr {
  display: flex;
  justify-content: center;
  align-items: center;
}
}

@media screen and (max-width: 1212px) {
  
  .vidd {
    display       : flex;
    flex-direction: column;
  }
  
  .par{
    font-size: 30px;
    width: 370px;
    padding: 30px;
    background: #FFFFFF;
    opacity: 1;
    line-height: 2rem;
  }
}

@media screen and (max-width: 600px) {

.parhead{
font-size: 14px;
line-height: 1.2rem;
}
.parheadar{
  font-size: 14px;
}
}
@media screen and (min-width: 1200px) {

.parhead{
width: 682px;
}

}

@media screen and (min-width: 1024px) {
  .sect{
    padding-left: 2.5rem;
    padding-right:2.5rem;
  }
  .phone-arabic{
    margin-left: -13px;
    margin-right: -13px;
  }
}


@media screen and (max-width: 464px) {
  .background-image {
    background-image: url('./assets/Bg-mobile.png');
    background-position: center center; 
    background-size: cover;
  }

}

@media screen and (max-width: 1024px) {
  .phone-arabic{
    margin-left: 0px;
    margin-right: 0px;
  }
  .sect{
    padding-top: 2.5rem;
    padding-left: 2.5rem;
  }
}

.background-image.flipped {
  transform: scaleX(-1);
}

.font-montserrat-arabic-black {
  font-family: 'ArabicFontBlack', sans-serif;

}

.orderrr {
  display: flex;

  justify-content: end;
  order          : -1;
}

p {
  line-height: 1rem;
}

.font-montserrat-arabic-bold {
  font-family: 'ArabicFontBold', sans-serif;
  font-weight: bold;
}

.font-regular {
  font-family: 'ArabicFontRegular', sans-serif;
}

.font-montserrat-arabic-regular {
  font-family: 'ArabicFontRegular', sans-serif;

}
.font-montserrat-arabic-light {
  font-family: 'ArabicFontLight', sans-serif;

}

.font-semi {
  font-family: 'ArabicFontSemi', sans-serif;
}

.font-montserrat-arabic-medium{
  font-family: 'ArabicFontMedium';
}

.beanz {
  font-family: monospace;
  font-weight: lighter;
  font-style : normal;
}


@font-face {
  font-family: 'ArabicFontBlack';
  src        : url('./assets/ArbFonts/ArbFONTS-Montserrat-Arabic-Black.ttf') format('truetype');

}

@font-face {
  font-family: 'ArabicFontBold';
  src        : url('./assets/ArbFonts/ArbFONTS-Montserrat-Arabic-Bold.ttf') format('truetype');

}

@font-face {
  font-family: 'ArabicFontRegular';
  src        : url('./assets/ArbFonts/ArbFONTS-Montserrat-Arabic-Regular.ttf') format('truetype');

}

@font-face {
  font-family: 'ArabicFontSemi';
  src        : url('./assets/ArbFonts/ArbFONTS-Montserrat-Arabic-SemiBold.ttf') format('truetype');

}
@font-face {
  font-family: 'ArabicFontLight';
  src        : url('./assets/ArbFonts/ArbFONTS-Montserrat-Arabic-Light.ttf') format('truetype');

}
@font-face {
  font-family: 'ArabicFontMedium';
  src        : url('./assets/ArbFonts/ArbFONTS-Montserrat-Arabic-Medium.ttf') format('truetype');

}



@font-face {
  font-family: 'EngBold';
  src        : url('./assets/montserrat//MONTSERRAT-BOLD.TTF') format('truetype');

}

@font-face {
  font-family: 'EngBlack';
  src        : url('./assets/montserrat/MONTSERRAT-BLACK.TTF') format('truetype');

}

@font-face {
  font-family: 'EngRegular';
  src        : url('./assets/montserrat/MONTSERRAT-REGULAR.TTF') format('truetype');

}

@font-face {
  font-family: 'EngSemi';
  src        : url('./assets/montserrat/MONTSERRAT-SEMIBOLD.TTF') format('truetype');

}
@font-face {
  font-family: 'EngMedium';
  src        : url('./assets/montserrat/MONTSERRAT-MEDIUM.TTF') format('truetype');

}


.font-engmedium{
  font-family: 'EngMedium';
}

.font-engbold {
  font-family: 'EngBold';
}

.font-engblack {
  font-family: 'EngBlack';
}

.font-engregular {
  font-family: 'EngRegular';
}

.font-engsemi {
  font-family: 'EngSemi';
}



@media only screen and (max-width: 577px) {
  .phone {
    width : 100%;
    height: 525px;

  }
  .iconss{
    left: -74px;
    right: auto;
    top: -185px;
    width: 220px;
  }
  .store-image {
    position: absolute;
    top: -357px;
    left: 275px;
    width: 56px;
    height: 55px;
    background: rgb(255, 255, 255);
    border-radius: 28px;
    padding: 16px;
    box-shadow: rgb(178, 206, 224) 10px 13px 199px;
    opacity: 1;
  }
  

  .no-line-height {
    line-height: 1;
  }

  .no-line-height-arabic {
    line-height: 1;
  }

  .icons {
    left   : -20px;
    width  : 216px;
    height : 145px;
    opacity: 1;
  }

  .btn-download-container {
    width     : 160px;
    text-align: center;
  }

  .btn-download {
    border-radius: 22px;
    font-size    : 13px;
    font-family  : Normal;
    width        : 100%;
    height       : 36px;
  }

  .span-point {
    font-size: 15px;
  }

  .descr2 {
    font-size: 9px;
  }

  .btn-download {
    border-radius: 22px;
    font-size    : 9px;
    font-family  : Normal;
    width        : 92px;
    height       : 27px;
  }
}



@media only screen and (max-width: 600px) {
  .phone-image {
    left: 50%;
  }
}

.menu-icons.justify-end {
  justify-content: space-between;
}


.header-background {
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(10px);
  transition: backdrop-filter 0.3s;
}


.navbar-expanded {
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(10px);
}

.back-home {
  display: inline-block;
    text-align: left;
}


#myBtn {
  position: fixed;
  bottom: 2rem;
  z-index: 99;
  border: 1px solid #0F2D47 ;
  outline: none;
  background-color: white;
  color: white;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out;
}

#myBtn.en{
  right: 3rem;
}

#myBtn.ar{
  left: 3rem;
}

#myBtn:hover {
  transform: scale(1.2);
}

@media only screen and (max-width: 900px) {
  #myBtn img {
    max-width: 2rem;
  }
}

.arabic-mobile {
  left: -113px;
  top : -382px;
}

.image-circle img {
  width        : 40%;
  height       : 100%;
  object-fit   : contain;
  border-radius: 50%;
}

.image-circle .coffee {
  width        : 35%;
  height       : 100%;
  object-fit   : contain;
  border-radius: 50%;
}

.image-circle .sticker {
  width        : 60%;
  height       : 100%;
  object-fit   : contain;
  border-radius: 50%;
}

.image-circle .mug {
  width        : 45%;
  height       : 125%;
  object-fit   : contain;
  margin-left: 8px;
  margin-bottom: 7px;
}

.paragraph-box {
  width        : 200px;
  padding      : 8px;
  border-radius: 8px;
  margin-top: -10px;
}


.phone-container {
  position: relative;
  overflow: hidden;
}

.phone-image {
  position  : absolute;
  width     : 365px;
  height    : 680px;
  left      : 0;
  top       : 0;
  transform : translateX(-50%);
  transition: left 0.3s ease-in-out;
}

@media only screen and (max-width: 600px) {
  .phone-image {
    left: 50%;
  }

  .paragraph-box{
    width:145px;
    height: 130px;
    overflow: visible;
  }

  .paragraph-box p{
    width:133px;
    margin-top: -3px;
  }



  .not_margined{
    margin: 0!important;
  }
}



.menu-icons.justify-end {
  justify-content: space-between;
}


.header-background {
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(10px);
  transition: backdrop-filter 0.3s;
}

.navbar-expanded {
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(10px);
}

.custom-scrollbar {
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0; 
}


.scroll-item{
  display: flex;
  animation: scroll 20s linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2600px); 
  }
}


.scroll-container {
  overflow-x: hidden;
  display: flex;
  flex-wrap: nowrap;
  scroll-behavior: smooth;
  padding-bottom: 16px; 
}

.scroll-container::-webkit-scrollbar {
  height: 8px; 
  display: none;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: white; 
  border-radius: 10px;
}

.scroll-item {
  flex: 0 0 auto;
  margin-right: 16px; 
}

.scroll-item:last-child {
  margin-right: 0;
}

.scroll-container2::-webkit-scrollbar {
  display: none;
}
.scroll-container2 {
  overflow-x: auto;
  display: flex;
  scroll-behavior: smooth;
  padding: 0;
}

.scroll-content {
  display: flex;
  width: fit-content;
}

.oval-container {
  flex: 0 0 auto;
  text-align: center;
  width: calc((100% - 46px * 2) / 3);
}

.w-300px {
  width: 85%;
}


@media (min-width:1025) and (max-width: 1280px) {

  .w-300px {
    width: 102%!important;
}
  
}

@media (max-width: 630px) {
  .wrapping{
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-left: 1.5rem;
    gap: 4rem;
  }
  .scroll-item{
    display: flex;
    animation: scroll 20s linear infinite;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-2600px); 
    }
  }
  .oval-container {
    flex: 0 0 auto;
    text-align: center;
    width: calc((100% - 46px * 2) / 2);
    margin-top: 20px;
  }
  .center-container {
    flex: 0 0 100%; 
    text-align: center;
    margin-top: 20px;
  }
}


@media (min-width: 1024px) {

.image-circle {
  width          : 100px;
  height         : 100px;
  border-radius  : 50%;
  display        : flex;
  align-items    : center;
  justify-content: center;
  overflow       : hidden;
}

.oval-image {
  width: 230px;
  height: 388px;
  border-radius: 50% 50% 50% 50% / 30% 30% 30% 30%;
  object-fit: cover; 
}


.oval-container h1 {
  text-align: center; 
  margin-top: 55px; 
}

.oval-container p {
  text-align: center;
}

}


@media (max-width:768px) {
  .coffee_ar_mob{
    font-size: 12px;
  }

  .coffee_eng_mob{
    font-size: 14px;
  }

}


@media (min-width:1025px) and (max-width: 1280px) {
  .coffee_ar{
    font-size: 20px;
  }
  
  .coffee_eng{
    font-size: 20px;
  }


}



@media (min-width:768px) and (max-width: 1024px) {

  .coffee_ar{
    font-size: 17px;
  }
  
  .coffee_eng{
    font-size: 17px;
  }
}


@media (max-width: 1024px) {
  .w-300px {
    width: 250px;
  }

  .oval-image {
    width: 132px;
    height: 202px;
    border-radius: 50% 50% 50% 50% / 30% 30% 30% 30%;
    object-fit: cover; 
  }

  
  
  .oval-container h1 {
    text-align: center; 
    font-size: 12px;
  }
  
  .oval-container p {
    text-align: center;
    font-size: 14px!important;
    width: 88%;
  }
  
}


@media (max-width: 1024px) {
  .scroll-container2 {
    max-width: 100%; 
    padding: 8px; 

  }
  .w-300px {
    width: 300px;
  }
  
}

.heightt{
  line-height: 30px;
}


@media all and (max-width:800px){

  .oval-container h1 {
    margin-top: 30px!important; 
  }
}

@media all and (max-width:430px){
  .overr{
    justify-content: flex-start;
  }
  
  .oval-container h1 {
    text-align: center; 
    margin-top: 70px;
    width: 175px; 
  }
}

.main_customer{
  display: flex;
  align-items: center;
  min-height: 100vh;
  background-color: white;
  overflow: hidden;
}


@media all and (min-width: 1025px){
  .main_customer{
    justify-content: flex-start;
  }
  
  .contain{
  position: relative;
  background-color: #DDF0F642;
  left: -300px;
  width: 1400px;
  height: 1400px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(32deg);
}


.big_phone_icon{
  position: absolute;
  width: 300px;
  height: 100px;
  border-radius: 30px;
  z-index: 10;
  transform: rotate(-32deg);
  
  
}
.icon_wrapper{
  position: relative;
  width: 100%;
  left: -50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.customer_child-4{
  margin-top: -200px;
}

.icon_wrapper .icon{
  position: absolute;
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  transform: rotate(calc(245deg/5 * var(--i)));
  transform-origin: 754px;
  
}

.testing_r{
  position:absolute;
  display: flex;
  flex-direction: column;
  width: 20rem;
  gap: 25px;
  justify-content: center;
  align-items: center;
  transform: rotate(calc(-150deg/ 5 * var(--j)));
}

}


@media screen and (min-width: 1024px) and (max-width: 1100px) {
  
  .icon:nth-of-type(1) .testing_r{
    transform: rotate(278deg);
    position: relative;
    left: -10%;
    
  }
  
  .icon:nth-of-type(2) .testing_r{
    transform: rotate(230deg);
    position: relative;
      left: 72%;
  }

  .icon:nth-of-type(3) .testing_r{
    transform: rotate(180deg);
    position: relative;
      left: 300%;
  }
  
  .icon:nth-of-type(4) .testing_r{
    transform: rotate(131deg);
    position: relative;
      left: 260%;
      bottom: 150%;
  }
  
  .icon:nth-of-type(5) .testing_r{
    transform: rotate(82deg);
    position: relative;
      left: 130%;
      bottom: 55%;
  }


  .big_phone_icon{
    position: absolute;
    width: 300px;
    height: 100px;
    border-radius: 30px;
    z-index: 10;
    transform: rotate(-32deg);
  }



  .english_icon:nth-of-type(1){
    transform:rotate(calc(409deg * var(--i))) translate(85px, -188px);
    position: relative;
    left:18% ;
    bottom: -4%;
  }
  
  .english_icon:nth-of-type(2){
    transform: rotate(calc(409deg * var(--i))) translate(93px, 20px) !important; 
    position: relative;
    left:31% ;
    bottom: -6%;
    margin-top:0!important;
  }
  
  .english_icon:nth-of-type(3){
    position: relative;
    left:32% ;
  }

  .english_icon:nth-of-type(4){
    position: relative;
    left:20% ;
  }
  .english_icon:nth-of-type(5){
    transform:rotate(calc(409deg * var(--i))) translate(-70px, 117px);
    position: relative;
    left:37% ;
    bottom: 0%;
  }

}

@media screen and (min-width: 1100px) and (max-width: 1200px) {

  .icon:nth-of-type(1) .testing_r{
    transform: rotate(278deg);
    position: relative;
    left: -10%;
    
  }
  
  .icon:nth-of-type(2) .testing_r{
    transform: rotate(230deg);
    position: relative;
      left: 60%;
  }

  .icon:nth-of-type(3) .testing_r{
    transform: rotate(180deg);
    position: relative;
      left: 200%;
  }
  
  .icon:nth-of-type(4) .testing_r{
    transform: rotate(131deg);

      position: relative;
      left: 180%;
      bottom: 150%;
  }
  
  .icon:nth-of-type(5) .testing_r{
    transform: rotate(82deg);
    position: relative;
      left: 80%;
      bottom: 35%;
  }

  .big_phone_icon{
    position: absolute;
    width: 300px;
    height: 100px;
    border-radius: 30px;
    z-index: 10;
    transform: rotate(-32deg);
  }

  .english_icon:nth-of-type(1){
    transform:rotate(calc(409deg * var(--i))) translate(125px, -200px);
    position: relative;
    left:18% ;
    bottom: -4%;
  }

  
  .english_icon:nth-of-type(2){
    transform: rotate(calc(409deg * var(--i))) translate(194px, 15px) !important; 
    position: relative;
    left:30% ;
    bottom: -6%;
    margin-top:0!important;
  }
  
  .english_icon:nth-of-type(3){
    transform:rotate(calc(409deg * var(--i))) translate(100px, 83px)!important;

    position: relative;
    left:32% ;
  }

  .english_icon:nth-of-type(4){
    position: relative;
    left:20% ;
  }
  .english_icon:nth-of-type(5){
    transform:rotate(calc(409deg * var(--i))) translate(-100px, 140px);
    position: relative;
    left:37%;
    bottom: 0%;
  }

}



@media screen and (min-width: 1200px) and (max-width: 1300px) {

  .icon:nth-of-type(1) .testing_r{
    transform: rotate(278deg);
    position: relative;
    left: -10%;
    
  }
  
  .icon:nth-of-type(2) .testing_r{
    transform: rotate(230deg);
    position: relative;
      left: 60%;
  }

  .icon:nth-of-type(3) .testing_r{
    transform: rotate(180deg);
    position: relative;
      left: 115%;
  }
  
  .icon:nth-of-type(4) .testing_r{
    transform: rotate(131deg);
    position: relative;
      left: 110%;
      bottom: 120%;
  }
  
  .icon:nth-of-type(5) .testing_r{
    transform: rotate(82deg);
    position: relative;
      left: 50%;
      bottom: 30%;
  }

  .big_phone_icon{
    position: absolute;
    width: 300px;
    height: 100px;
    border-radius: 30px;
    z-index: 10;
    transform: rotate(-32deg);
    
    
  }

  .english_icon:nth-of-type(1){
    transform:rotate(calc(409deg * var(--i))) translate(180px, -250px);
    position: relative;
    left:18% ;
    bottom: -4%;
  }

  
  .english_icon:nth-of-type(2){
    transform: rotate(calc(409deg * var(--i))) translate(325px, 15px) !important; 
    position: relative;
    left:33% ;
    bottom: -4%;
    margin-top:0!important;
  }
  
  .english_icon:nth-of-type(3){
    transform:rotate(calc(409deg * var(--i))) translate(183px, 250px)!important;

    position: relative;
    left:32% ;
  }

  .english_icon:nth-of-type(4){
    transform:rotate(calc(409deg * var(--i))) translate(38px, 133px)!important;

    position: relative;
    left:20% ;
  }
  .english_icon:nth-of-type(5){
    transform:rotate(calc(409deg * var(--i))) translate(-162px, 187px);
    position: relative;
    left:37%;
    bottom: 0%;
  }


}
@media all and (min-width: 1330px)  {
  .icon:nth-of-type(4) .testing_r{
    transform: rotate(131deg);
    left: -139%;
  }
}


@media screen and (min-width: 1300px) and (max-width: 1400px) {



  .icon:nth-of-type(3) .testing_r{
    transform: rotate(180deg);
    position: relative;
      left: 65%;
  }
  

  
  .icon:nth-of-type(5) .testing_r{
    transform: rotate(82deg);
    position: relative;
      left: 10%;
      bottom: 20%;
  }

  .big_phone_icon{
    position: absolute;
    width: 300px;
    height: 100px;
    border-radius: 30px;
    z-index: 10;
    transform: rotate(-32deg);
    
    
  }

  .english_icon:nth-of-type(1){
    transform:rotate(calc(409deg * var(--i))) translate(210px, -250px);
    position: relative;
    left:18% ;
    bottom: -4%;
  }

  
  .english_icon:nth-of-type(2){
    transform: rotate(calc(409deg * var(--i))) translate(444px, 10px) !important; 
    position: relative;
    left:32% ;
    bottom: -4%;
    margin-top:0!important;
  }
  
  .english_icon:nth-of-type(3){
    transform:rotate(calc(409deg * var(--i))) translate(306px, 250px)!important;

    position: relative;
    left:32% ;
  }

  .english_icon:nth-of-type(4){
    transform:rotate(calc(409deg * var(--i))) translate(32px, 222px)!important;

    position: relative;
    left:20% ;
  }
  .english_icon:nth-of-type(5){
    transform:rotate(calc(409deg * var(--i))) translate(-202px, 194px);
    position: relative;
    left:37%;
    bottom: 0%;
  }

}


@media all and (min-width: 1025px){
  
.icon:nth-of-type(1) .testing_r{
  transform: rotate(278deg);
 
  
}

.icon:nth-of-type(2) .testing_r{
  transform: rotate(230deg);
 
}

.icon:nth-of-type(3) .testing_r{
  transform: rotate(180deg);
}

.icon:nth-of-type(4) .testing_r{
  transform: rotate(131deg);
}

.icon:nth-of-type(5) .testing_r{
  transform: rotate(82deg);
}

.for_relative{
  position:relative;
  inset:0;
  width:100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testing_r img{
  z-index:10;
  padding:8px;
  position: relative;
}

.testing_r p{
  font-size: 12px;
    width: 421px;
    text-align: center;
    margin: 4px 0px;
}
.img_wrapper{
  position: relative;
}
 .img_wrapper:before{
  content:"";
  display: block;
  position: absolute;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius:50%;
  top:50%;
  left:50%;
  transform: translate(-50%, -50%);
  width:120px;
  height:120px;
}

}

@media all and (max-width:810px) {
  .contain{
    width: 600px;
    min-height: 600px;
  }
  .english_contain{
    width: 600px;
    min-height: 600px;
  }
  
  .icon_wrapper .icon{
    transform-origin: 360px;
  }
  .english_icon_wrapper .english_icon{
    transform-origin: 360px;
  }

  .big_phone_icon{
    width: 200px;
    height: 40vh;
  }
}


.hp{
  width: 134px;
}


.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white; 
  text-align: center;
}


.icon_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.text_content {
  text-align: center;
  position: relative;
  width: 100%;
  bottom: 160px;
    left: 213px;
}

h1 {
  font-size: 16px; 
  margin: 0;
  padding: 0;
}

p {
  font-size: 14px; 
  margin: 0;
  padding: 0;
}


@media  all and (min-width: 1025px){
  
.english_contain {
  position: relative;
  background-color: #DDF0F642;
  right: -300px;
  width: 1400px;
  height: 1400px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(32deg);
  }


.english_icon{
  position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    transform: rotate(calc(409deg * var(--i)));
    transform-origin: -660px 80px;
}


.english_icon:nth-of-type(1) .testing_r {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
  transform: rotate(83deg);
}
.english_icon:nth-of-type(2) .testing_r {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
  transform: rotate(132deg);
}
.english_icon:nth-of-type(3) .testing_r {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 25px; 
  justify-content: center;
  align-items: center;
  transform: rotate(182deg);
}

.english_icon:nth-of-type(4){
  transform:rotate(calc(409deg * var(--i))) translate(-40px, -105px);
}

.english_icon:nth-of-type(2){
  transform: rotate(calc(409deg * var(--i))) translate(0px, 83px);
  margin-top:0!important;
}

.english_icon:nth-of-type(3){
  transform: rotate(calc(409deg * var(--i))) translate(0px, 83px);
}

.english_icon:nth-of-type(4){
  transform: rotate(calc(409deg * var(--i))) translate(0px, 63px);
}

.english_icon:nth-of-type(5){
  /*la 3alliya bel english */
}

.icon:nth-of-type(5){
  /*la 3alliya bel arabe*/
}
.english_icon:nth-of-type(4) .testing_r {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
  transform: rotate(230deg);
}
.english_icon:nth-of-type(5) .testing_r {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;
  transform: rotate(279deg);
}

 .icon {
  position: absolute;
  flex-shrink: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  transform: rotate(calc(245deg/5 * var(--i)));
  transform-origin: 760px;
}

.english_icon_wrapper{
  position: relative;
    width: 100%;
    right: -52%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

}


@media all and (max-width: 1024px) {

  .big_phone_icon{
    display: none;
  }
  .main_customer{
    justify-content: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .testing_r{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
  }

  .testing_r > *{
    margin: 0!important;
    text-align: center;
  }

  .testing_r > h1{
    padding-top: 1.5rem!important;
  }
  .testing_r > p{
    width: 50%;
  }

  .icon_wrapper,
  .english_icon_wrapper{
    display: flex;
    flex-direction: column;
    gap: 60px;
  }


  .customer_child-2{
    transform: unset!important;
  }

  .img_wrapper{
    position: relative;
  }

  .img_wrapper img{
    padding: 8px;
    position: relative;
  }
   .img_wrapper:before{
    content:"";
    display: block;
    position: absolute;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius:50%;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
  }
}

/* ORDER::::::::::: */



.order_box {
  display: inline-block;
  width: 300px;
  margin: 10px;
  text-align: center;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.order_box img {
  
    width: 90px;
    height: auto;
    margin: auto; 
  
}

.order_box h2 {
  font-size: 20px;
  margin: 5px 0;
}

.order_box p {
  font-size: 16.5px;
}


@media only screen and (max-width: 640px) {

  .order_box p {
    font-size: 12px;
  }

  .order_box h2 {
    font-size: 18px;
    margin-top: -7px;
    margin-bottom: 5px;
  }
  .order_box img {
  
    width: 80px;
    height: auto;
    margin: auto; 
  
}
}


@media only screen and (max-width: 1024px) {
  .order_container {
    display: flex;
    overflow-x: auto;
    justify-content: flex-start; 
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
  }

  .order_box {
    flex: 0 0 100%;
    margin: 10px;
    text-align: center;
    scroll-snap-align: start;
    width: 300px;

  }

  .order_box p{
    word-wrap: break-word;
    margin-left: auto;
    margin-right: auto;
    width: 147px;
}

}


.order_container_wrapper {
  position: relative;
  text-align: center;
  overflow: hidden;
}

.arrow_buttons_wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.order_container {
  display: flex;
  overflow-x: hidden;
  position: relative; 
}

@media all and (min-width: 1025px){
  
.order_container {
  justify-content: space-evenly;
}

}
.arrow_button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.left_button {
  left: 40px;
}

.right_button {
  right: 40px;
}

.right_icon, .left_icon {
  width: 30px;
  height: 30px;
  border-right: 3px solid #0F2D47;
  border-bottom: 3px solid #0F2D47;
}

.right_icon{
  transform: rotate(-45deg);
}

.left_icon{
  transform: rotate(135deg);
}



/* Gallery  */

.grid-container-gallery {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      grid-gap: 35px;
      width: 100%;
      border-radius: 100px;
      padding-left: 50px;
}

.item1 {
  grid-row: span 2;
  width: 765px;
}

.item1 img:first-child {
  width: 940px;
  height: 665px;
  border-radius: 10px;
}


.button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 6.5rem;
  grid-column: 2;
  cursor: pointer; 
  margin-top: -16px;
}

.button-container-arabic{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 5rem;
  grid-column: 1;
  cursor: pointer; 
  margin-top: -16px;
}


@media screen and (min-width:1300px) and (max-width: 1400px) {
  .grid-container-gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 30px;
    width: 100%;
    border-radius: 100px;
}

.item1 {
  grid-row: span 2;
  width: 700px;
}

.item1 img:first-child {
  width: 940px;
  height: 660px;
  border-radius: 10px;
}

.item2 img,
.item3 img {
  width: 400px;
  height: 300px; 
  border-radius: 10px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5rem!important;
  grid-column: 2;
  cursor: pointer; 
}
.gallery-video{
  width: 740px;
  height: 530px!important;
  justify-self: end;
  object-fit: cover;
}
}


@media screen and (min-width:1200px) and (max-width: 1300px) {
  .grid-container-gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 30px;
    width: 100%;
    border-radius: 100px;
}

.item1 {
  grid-row: span 2;
  width: 600px;
}

.item1 img:first-child {
  width: 940px;
  height: 660px;
  border-radius: 10px;
}

.item2 img,
.item3 img {
  width: 400px;
  height: 300px; 
  border-radius: 10px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5rem!important;
  grid-column: 2; 
  cursor: pointer; 
}
.gallery-video{
  width: 740px;
  height: 530px!important;
  justify-self: end;
  object-fit: cover;
}
}


@media screen and (min-width: 1100px) and (max-width: 1200px) {
  .grid-container-gallery {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 30px;
    width: 100%;
    border-radius: 100px; 
}
  .grid-container-gallery-ar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 30px;
    width: 100%;
    border-radius: 100px; 
    padding-left: 5rem;
}

.item1 {
  grid-row: span 2;
  width: 500px;
}

.item1 img:first-child {
  width: 940px;
  height: 660px;
  border-radius: 10px;
}

.item2 img,
.item3 img {
  width: 400px;
  height: 300px; 
  border-radius: 10px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5rem!important;
  grid-column: 2;
  cursor: pointer;
}
.gallery-video{
  width: 740px;
  height: 530px!important;
  justify-self: end;
  object-fit: cover;
}
}


@media all and (max-width: 1100px) {
  .grid-container-gallery {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-radius: 100px;   
    padding-left: 0px;
}

.item1 {
  grid-row: span 2;
  width: 400px;
}


.item1 img:first-child {
  width: 940px;
  height: 315px; 
  border-radius: 10px;
}

.item2 img,
.item3 img {
  width: 400px!important;
  height: 300px!important; 
  border-radius: 10px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 17rem!important;
  padding-right: 0rem!important;
  grid-column: 2;
  cursor: pointer;
}


.button-container-arabic{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 15rem!important;
  grid-column: 1;
  cursor: pointer;
}

.gallery-video{
  width: 740px;
  height: 300px!important;
  justify-self: end;
  object-fit: cover;
}
}
@media screen and (max-width: 500px) {
  .grid-container-gallery {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-radius: 100px;
    padding-left: 0px;
}

.item1 {
  grid-row: span 2;
  width: 400px;
}


.item1 img:first-child {
  width: 940px;
  height: 250px; 
  border-radius: 10px;
}

.item2 img,
.item3 img {
  width: 400px;
  height: 300px; 
  border-radius: 10px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 15rem!important;
  padding-right: 0rem!important;
  grid-column: 2;
  cursor: pointer; 
}

.button-container-arabic{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 12rem!important;
  grid-column: 1;
  cursor: pointer; 
}

.view-more-button-text {
  border: none;
  border-radius: 5px;
  font-size: 12px!important;
  cursor: pointer;
  margin-right: 10px;
}
.view-more-button-text-ar {
  border: none;
  border-radius: 5px;
  font-size: 12px!important;
  cursor: pointer;
  margin-right: 10px;
}
}


@media screen and (max-width: 430px) {
  .grid-container-gallery {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-radius: 100px;
    padding-left: 0px;
}

.item1 {
  grid-row: span 2;
  width: 370px;
}


.item1 img:first-child {
  width: 940px;
  height: 250px; 
  border-radius: 10px; 
}

.item2 img,
.item3 img {
  width: 400px;
  height: 300px; 
  border-radius: 10px;
}
}
@media screen and (max-width: 410px) {

  .item1 {
    grid-row: span 2;
    width: 340px;
  }

}

@media all and (min-width:1400px) and (max-width: 1441px) {
  .button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5rem!important;
    grid-column: 2;
    cursor: pointer; 
  }
}

.view-more-button-text-ar {
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  margin-left: 10px;
}
.view-more-button-text {
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  margin-right: 10px;
}

.view-more-button-icon {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.video-thumbnail {
  position: relative;
  display: inline-block;
}

.play-iconn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 2;
  width: 85px;
  height: 85px;
}

.gallery-video{
  width: 765px;
  height: 535px;
  justify-self: end;
  object-fit: cover;
  border-radius: 10px; 
}
.item2 img,
.item3 img {
  width: 350px;
  height: 250px; 
  border-radius: 10px;
  
}

@media screen and (min-width: 1915px) and (max-width:2880px) {

  .grid-container-gallery {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
    grid-gap: 35px;
    width: 100%;
    border-radius: 100px;
    justify-content: center;
}
  .grid-container-gallery-ar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 35px;
    width: 100%;
    border-radius: 100px;
    justify-content: center;
}
.view-more-button-text {
  border: none;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
  width: max-content;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 18rem;
  cursor: pointer;
  margin-top: -16px;
}
.view-more-button-icon  img{
  max-width: none;
}

.gallery-video {
  width: 765px;
  height: 633px;
  justify-self: end;
  object-fit: cover;
  border-radius: 10px

}

.item2 img{
  width: 400px;
  height: 300px;
}
.item3 img{
  width: 400px;
  height: 300px;
}

}

@media screen and (min-width: 1500px) and (max-width:1914px) {

  .grid-container-gallery {
    display: grid;
    grid-template-columns:auto;
    grid-template-rows: 1fr 1fr;
    grid-gap: 35px;
    width: 100%;
    border-radius: 100px;
    justify-content: center;
}
.grid-container-gallery-ar {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 35px;
  width: 100%;
  border-radius: 100px;
  justify-content: center;
  padding-left: 10rem;
}

  .gallery-video {
    width: 765px;
    height: 628px;
    justify-self: end;
    object-fit: cover;
    border-radius: 10px

  }

  .item2 img{
    width: 400px;
    height: 300px;
  }
  .item3 img{
    width: 400px;
    height: 300px;
  }
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 9.5rem;
    grid-column: 2;
    cursor: pointer;
    margin-top: -16px;
    
  }
  .view-more-button-icon{
    max-width: initial;
  }
}


/* PartnerForm */

.backcolor{
  background: linear-gradient(270deg, #102E46 0%, #1B4160 0%, #0A1F2E 77%, #0B1F2F 100%);
}

.partner-section {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.partner-form {
  flex: 1;
  padding: 20px;
  border: 0px solid #ccc;
  border-radius: 8px;
}

.partner-form h2 {
  margin-top: 0;
  margin-bottom: 1rem;
}

.input-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.input-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.input-group-arabic{
  flex: 1;
  display: flex;
  flex-direction: column;
}

.input-group label {
  margin-bottom: 10px;
  color: #D7D7D9;
  font-size: 16px;
  text-align: left;
}
.input-group-arabic label {
  margin-bottom: 10px;
  color: #D7D7D9;
  font-size: 15px;
  text-align: right;
}
.input-group-arabic {
  margin-bottom: 10px;
  color: #D7D7D9;
  font-size: 13px;
  text-align: right;
  direction: rtl;
}

.input-group-arabic input{
  width: 81%;
  padding: 12px;
  margin-bottom: 10px;
  direction: rtl;
}
.input-group input {
  width: 81%;
  padding: 12px;
  margin-bottom: 10px;
}

.login-text {
  margin-top: 10px;
}

.partner-image {
  padding: 20px;
  text-align:  -webkit-center;
}

.partner-image img {
  max-width: 100%;
  height: 450px;
}

.partner-image p {
  margin-top: 35px;
}

.styled-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #a6aaad;
  background-color: #56646F;
  border-radius: 10px;
}

#popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

#popup-content {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin: 15% auto;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid #888888;
  width: 36rem;
  height: 19rem;
  position: relative;
}

#popup-close {
  position: absolute;
  right: 20px;
  top: 20px;
  color: #888888;
  text-decoration: none;
  margin-bottom: 2rem;
  cursor: pointer;
}

#popup-confirmation {
  align-self: center;
  margin-top: 1rem;
}

#popup-content p {
  margin: 1rem 3rem 1rem 3rem;
  font-weight: 1rem;
  text-align: center;
  line-height: 1.3rem;
}

#popup-content button {
  border: 1px solid #0F2D47;
  border-radius: 20px; 
  margin-top: 1.5rem; 
  width: 13rem; 
  align-self: center;
  padding: 0.5rem
}

#popup-content button:hover {
  background: #0F2D47;
  color: white;
}

#delete-container {
  display: flex;
  justify-content: flex-start;
}

#delete-container.ar{
  flex-direction: row-reverse;
}

#delete-container-img {
  max-height: 100vh;
}

#back-home {
  padding: 2rem;
  flex: 1;
  max-width: 100%;
}

#back-home.ar{
 direction: rtl;
}

#back-home-content{
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

#back-home-content img {
  transform: rotate(270deg);
  height: 3rem;
  width: 3rem;
  margin-right: 0.2rem;
}

#back-home-content img.ar{
  transform: rotate(90deg);
}

#back-home-content h1 {
  color: #335A79;
  margin-left: 0.5rem;
}

#delete-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
}

#delete-content img {
  width: 10rem;
  height: 7rem;
}

#delete-content p {
  color: #6CC4DC;
  margin-bottom: 1rem;
  font-size: 2rem;
}

#delete-form {
  width: 24rem;
  margin-top: 2rem;
}

#delete-form h3 {
  margin-bottom: 0.5rem;
}

#delete-form-input {
  display: flex;
  margin-bottom: 1rem;
}

#delete-form-input.ar {
  direction: rtl;
}

#delete-form-input select {
  background: white; 
  margin-right: 0.5rem; 
  font-size: 0.8rem;
  border-radius: 10px;
  border: 1px solid #C0BDCC;
  color: #585858;
}

#delete-form-input select.ar {
  margin-left: 0.5rem;
  margin-right: 0rem;
}

#delete-form-input select:hover {
  background: #abd1f35e;
}

#delete-form-input input {
  border: 1px solid #C0BDCC;
  border-radius: 10px;
  width: 100%;
  background: white;
  max-width: 24rem;
  padding: 0.8rem;
  font-size: 0.8rem;
}

#delete-form button {
  background: #0F2D47;
  border: 1px solid #0F2D47;
  border-radius: 12px;
  color: white;
  width: 100%;
  height: 3rem;
}

@media only screen and (max-width: 944px) {
  #delete-container-img {
    display: none;   
  }
}

@media only screen and (max-width: 600px) {
  #popup-content {
    width: 22rem;
    height: 21rem;
    position: relative;
    font-size: 0.8rem;
    padding: 0.5rem;
    margin: 56% auto;
  }
  #popup-content button {
    margin-top: 1rem;
    width: 10rem;
  }
  #popup-confirmation {
    margin-top: 3rem;
    max-width: 3rem;
    max-height: 3rem;
  }
  #back-home-content img {
    height: 2rem;
    width: 2rem;
    margin-right: 0.2rem;
  }
  #back-home-content h1 {
    font-size: 0.8rem;
  }
  #back-home {
    font-size: 0.8rem;
  }
  #delete-content img {
    width: 7rem;
    height: 4rem;
  }
  #delete-content p {
    font-size: 1.1rem;
  }
  #delete-form {
    width: 18rem;
    margin-top: 2rem;
  }
  #delete-form-input select {
    font-size: 0.6rem;
  }
  #delete-form-input input {
    font-size: 0.6rem;
  }
}

.styled-input-arabic {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #56646F;
  background-color: #56646F;
  border-radius: 10px;
}

.styled-input-arabic::placeholder {
  text-align: right; 
  color: #BCBEBF;
  font-size: 13px;
}
.styled-input::placeholder {
  text-align: left; 
  padding-left: 0.5rem;
  color: #BCBEBF;
  font-size: 13px;
}

.partner-styled-button {
  background: #0E293F;
  color: white;
  border: none;
  padding: 12px 20px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  width: 100%;
  font-size: 15px;
  margin-top: 1rem;
}
.partner-form p {
  text-align: left;
  line-height: 4rem;
  margin-bottom: 2rem;
}
.partner-button-container {
  display: flex;
  flex-direction: column;
}
.partner-button-container-arabic {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}
.centered-text {
  text-align: right;
  display: flex;
    align-self: center;
  font-size: 14px;
}
.centered-text-arabic {
  text-align: right;
  font-size: 11px;
}

.centered-text-arabic a{
  text-decoration: underline;
}
.centered-text a{
  text-decoration: underline;
}
.partner-text {
  flex: 1;
}

.centered-paragraph-container{
  display: flex;
  flex-direction: column;
  align-items: center; 
  text-align: center;
}

@media screen and (max-width: 1190px) {
  .partner-section {
    flex-direction: column;
    padding: 0rem;
  }

  .partner-form,
  .partner-image {
    padding: 20px;
    text-align: center;
  }

  .partner-image img {
    display:none;
  }

  .input-row {
    flex-direction: column;
    gap: 10px;
  }

  .input-group {
    width: 100%;
  }
  .input-group input {
    width: 100%;
    padding: 14px;
    margin-bottom: 10px;
  }

.input-group-arabic {
  width: 100%;
}

.input-group-arabic input{
  width: 100%;
  padding: 14px;
  margin-bottom: 10px;
}

  .centered-text {
    text-align: center;
    padding-left: 0;
    padding-top: 8px;
  }

  .centered-text-arabic {
    text-align: center;
    padding-right: 0;
    font-size: 12px;
    padding-top: 8px;
  }

  .partner-text {
    text-align: left;
    padding-bottom: 20px;
  }

  .partner-button-container {
    align-items: center;
  }
  .partner-button-container-arabic {
   
    align-items: center;
  }

  .partner-styled-button {
    background: #0E293F;
    color: white;
    border: none;
    padding: 14px 20px;
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
    width: 55%;
  }
  .partner-form p {
    text-align: left;
    line-height: 1.5rem;
  }
  .partner-image p {
    margin-top: 0px;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 13px;
  }

  .partner-image {
    padding: 0px;
    text-align: center;
  }
  .partner-form p {
    text-align: left;
    line-height: 1.5rem;
    margin-bottom: 0;
  }
}

@media all and (min-width: 1190px) and (max-width: 1440px) {
  .centered-text{
    padding-left: 0;
    font-size: 11px;
  }
}
@media all and (min-width: 1190px) and (max-width: 1390px) {
  .centered-text-arabic {
    text-align: right;
    font-size: 11px;
  }
}


/* FAQS */

.faq-item {
  margin-bottom: 20px;
  width: 100%;
}

.faq-question {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #D7D7D9;
  padding-bottom: 25px;
  margin-bottom: 24px;
}

.faq-question-arabic{
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #D7D7D9;
  padding-bottom: 25px;
  margin-bottom: 24px;
  flex-direction: row-reverse;
}

.faq-arrow {
  width: 10px;
  height: 10px;
  border: 2px solid #335A79;
  border-top: none;
  border-left: none;
  margin-left: auto;
  transform: rotate(45deg);
  align-self: flex-start;
  transition: transform 0.3s ease-in-out;
}

.faq-arrow-arabic{
  width: 10px;
  height: 10px;
  border: 2px solid #335A79;
  border-top: none;
  border-left: none;
  margin-right: auto;
  transform: rotate(45deg);
  align-self: flex-start; 
  transition: transform 0.3s ease-in-out;
}

.faq-arrow.open {
  transform: rotate(225deg);
}
.faq-arrow-arabic.open {
  transform: rotate(225deg);
}

.flex-container-arabic {
  flex-direction: row-reverse;
  text-align: right;
}

.text-right-arabic {
  text-align: right;
}


.faq-answer {
  padding-top: 3px;
  color: #335A79;
  opacity: 80%;
  border-bottom: 1px solid #D7D7D9;
  padding-bottom: 10px; 
}

.faq-question.open + .faq-answer {
  display: block;
}

.faq-question.open {
  border-bottom-color: transparent;
  padding-bottom: 0;
}

.faq-question-arabic.open{
  border-bottom-color: transparent;
  padding-bottom: 0;
}

.maill{
  text-decoration: underline;
}

@media all and (min-width: 1170px) and (max-width: 1350px) {
.wid{
  width: 100%;
}
}

@media (min-width: 800px) and (max-width: 1170px) {

  .flex-container {
    flex-direction: column;
    align-items: center;
  }
  .flex-container-arabic {
    flex-direction: column;
    text-align: right;
  }
  .faq-item {
    width: 100%;
  }
  .wid{
    width: 100%;
    margin-bottom: 2rem;
  }
  .faq-question {
   padding-bottom: 4px;
    margin-bottom: 4px;
  }
  .faq-question-arabic{
    padding-bottom: 10px;
    margin-bottom: 4px;
  }
.firsthead-ar{
  font-size: 19px;
}
.firsthead{
  font-size: 19px;
}

.secondhead-ar{
  font-size: 30px;
}
.secondhead{
  font-size: 30px;
}
.firstp-ar{
  font-size: 16px;
}
.firstp{
  font-size: 16px;
}

.secondp-ar{
  font-size: 14px;
}
.secondp{
  font-size: 14px;
}

.thirdp-ar{
  font-size: 14px;
}
.thirdp{
  font-size: 14px;
}
}


@media screen and (max-width: 800px) {
  .flex-container {
    flex-direction: column;
    align-items: center;
    padding-left: 2rem!important;
    padding-right: 2rem!important;
  }
  .flex-container-arabic {
    flex-direction: column;
    text-align: right;
    padding-left: 2rem!important;
    padding-right: 2rem!important;
  }
  .faq-item {
    width: 100%;
  }

  .faq-question {
   padding-bottom: 4px;
    margin-bottom: 4px;
  }
  .faq-question-arabic{
    padding-bottom: 10px;
    margin-bottom: 4px;
  }

  .firsthead-ar{
    font-size: 15px;
  }
  .firsthead{
    font-size: 15px;
  }
  
  .secondhead-ar{
    font-size: 26px;
  }
  .secondhead{
    font-size: 26px;
  }
  .firstp-ar{
    font-size: 12px;
  }
  .firstp{
    font-size: 12px;
  }
  
  .secondp-ar{
    font-size: 10px;
  }
  .secondp{
    font-size: 10px;
  }
  
  .thirdp-ar{
    font-size: 10px;
  }
  .thirdp{
    font-size: 10px;
  }
}

/* Footer */
.footer-web-background{
  background-image: url("./assets/web-footer-bg.png");
  background-size: cover;
}

.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.footer-content h3 {
  text-transform: capitalize;
  line-height: 3rem;
}

.footer-content p {
  max-width: 627px;
  margin: 20px auto 12px auto;
  line-height: 28px;
  font-size: 14px;
  color: #cacdd2;
}
.footer-content-ar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.footer-content-ar h3 {
  text-transform: capitalize;
  line-height: 3rem;
}

.footer-content-ar p {
  max-width: 627px;
  margin: 20px auto 12px auto;
  line-height: 28px;
  font-size: 14px;
  color: #cacdd2;
}

.socials {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 0 5px 0;
}

.socials li {
  margin: 0 20px;
}

.socials a {
  text-decoration: none;
  color: #D7D7D9;
  padding: 5px;
}

.socials a i {
  width: 20px;
  transition: color 0.4s ease;
}

.footer-bottom {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
}

.footer-hr{
  opacity: 1;
  margin-bottom: 2rem;
}

.footer-bottom p {
  float: left;
  font-size: 14px;
  word-spacing: 2px;
}

.footer-bottom p a {
  color: #ccc;
  font-size: 16px;
  text-decoration: none;
}

.footer-bottom span {
  text-transform: uppercase;
}

.footer-menu {
  float: right;
}

.footer-menu ul {
  display: flex;
}

.footer-menu ul li {
  padding-right: 10px;
  display: block;
}

.footer-menu ul li a {
  color: #cfd2d6;
  text-decoration: none;
}

.footer-menu ul li a:hover {
  color: #27bcda;
}

.footer-section {
  padding:0px 0px 15px 0px;
  display: flex;
  justify-content: space-between;
}

.footer-section h3 {
  font-size:16px;
  padding:0;
  margin:0px 0px 15px 0px;
  font-family: 'EngBlack';
}

.footer-section a {
  color:#D7D7D9;
  font-size:16px;
  line-height:40px!important;
  text-decoration:none;
  display:block;
  font-family: 'EngRegular';
}

.footer-section a:hover {
  text-decoration:underline;
}

.footer-section-ar {
  padding:0px 0px 15px 0px;
  display: flex;
  justify-content: space-between;
}

.footer-section-ar h3 {
  font-size:16px;
  padding:0;
  margin:0px 0px 15px 0px;
  font-family: 'ArabicFontBlack';
}

.footer-section-ar a {
  color:#D7D7D9;
  font-size:16px;
  line-height:40px!important;
  text-decoration:none;
  display:block;
  font-family: 'ArabicFontRegular';
}

.footer-section-ar a:hover {
  text-decoration:underline;
}

.footer-group {
  margin:0px 50px 0px 0px;
}
.footer-group-ar {
  margin:0px 0px 0px 50px;
}
.footer-section-ar.right-align {
  text-align: right;
}

@media screen and (min-width:600px) and (max-width: 999px) {
  .footer-section h3 {
    font-size:16px;
    font-weight:400;
    margin:0px 0px 15px 0px;
  }

  .footer-section a {
    font-size:13px;
    font-weight:300;
    line-height:22px;
  }
  
  .footer-group {
    margin:0px 20px 0px 0px;
  }
}

.footer-group ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-group li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.footer-group img {
  margin-right: 15px;
}

.footer-group a {
  color: #D7D7D9;
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
}

.footer-group a:hover {
  text-decoration: underline;
}
.footer-group-ar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer-group-ar li {
  margin-bottom: 10px;
}

.footer-group-ar img {
  margin-left: 15px;
}

.footer-group-ar a {
  color: #D7D7D9;
  text-decoration: none;
  font-size: 16px;
  line-height: 20px;
}

.footer-group-ar a:hover {
  text-decoration: underline;
}

@media (max-width: 1100px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }
  .footer-content-ar {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    flex-direction: column;
    align-items: center;
  }
  .footer-section h3 {
    font-size:18px;
    padding:0;
    margin:0px 0px 15px 0px;
    font-family: 'EngBold';
  }

  .footer-group {
    text-align: center;
    padding-bottom: 1.2rem;
  }
  .footer-group span {
    display: contents;
    
  }
  .footer-group img {
    width: 17px;
    margin-right: 0;    
  }
  .footer-group li {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .footer-section-ar {
    flex-direction: column;
    align-items: center;
  }
  .footer-section-ar h3 {
    font-size:18px;
    padding:0;
    margin:0px 0px 15px 0px;
    font-family: 'ArabicFontBold';
  }

  .footer-group-ar {
    text-align: center;
    padding-bottom: 1.2rem;
  }
  .footer-group-ar span {
    display: contents;
    
  }
  .footer-group-ar img {
    width: 17px;
    margin-right: 0;    
  }
  .footer-group-ar li {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .footer-group-ar {
    margin: 0;
    text-align: center;
  }

  .footer-bottom {
    padding-bottom: 2rem;
  }

  .footer-menu ul {
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
  }

  .footer-section h3 {
    margin: 0 0 15px;
  }

  .footer-group a {
    line-height: 30px;
  }

  .footer-group-ar a {
    line-height: 30px;
    font-family: 'ArabicFontRegular';
  }
  .footer-content p {
    max-width: 230px;
    margin: 20px auto 0px auto;
    line-height: 28px;
    font-size: 14px;
    color: #cacdd2;
  }
  .footer-content-ar p {
    max-width: 313px;
    margin: 20px auto 0px auto;
    line-height: 32px;
    font-size: 14px;
    color: #cacdd2;
  }


  .footer-section {
    padding:25px 0px 25px 0px;
  }
  
  .footer-section h3 {
    font-size:18px;
    margin:4px 0px 15px 0px;
  }

  .footer-section a {
    font-size:14px;
    font-weight:300;
    line-height:28px;
  }
  
  .footer-group {
    width:300px;
    margin: 0;
  }

  .footer-section-ar {
    padding:25px 0px 25px 0px;
  }
  
  .footer-section-ar h3 {
    font-size:18px;
    margin:4px 0px 15px 0px;
  }

  .footer-section-ar a {
    font-size:14px;
    font-weight:300;
    line-height:28px;
  }
  
  .footer-group-ar {
    width:300px;
    margin: 0;
  }
  
  .footer-group-ar > * {
    vertical-align:top;
  }


  .footer-bottom p {
    float: none;
    font-size: 12px;
  }

  .footer-menu ul {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
  }

  .socials li {
    margin: 0 12px;
  }
}

@media all and (max-width:500px){
  .footer-web-background{
    background-image: url("./assets/mob-footer-bg.png");
    background-size: cover;
  }
}


/* Article Privacy */

.privacy-section{
  padding-left: 8rem;
  padding-right: 8rem;
}

.article{
  padding-bottom: 3rem;
}

.article h2{
  text-align: center;
  color: #335A79;
  font-size: 35px;
  padding-bottom: 1.5rem;
  font-family: "EngMedium";
}

.article p {
  line-height: 2.2rem;
  color: #00121F;
  font-size: 18px;
  text-align: left;
  font-family: "EngMedium";
}
.article ul {
  line-height: 2.2rem;
  color: #00121F;
  font-size: 18px;
  font-family: "EngMedium";
}
.article ul li {
  
  cursor:auto;
}

.blue-p{
  color: #6CC4DC!important;
  text-decoration: underline;
}

.article-ar{
  padding-bottom: 3rem;
}

.article-ar h2{
  text-align: center;
  color: #335A79;
  font-size: 35px;
  padding-bottom: 1.5rem;
  font-family: "ArabicFontMedium";
}

.article-ar p {
  line-height: 2.2rem;
  color: #00121F;
  font-size: 18px;
  text-align: right;
  font-family: "ArabicFontMedium";
}
.article-ar ul {
  line-height: 2.2rem;
  color: #00121F;
  text-align: right;
  font-size: 18px;
  font-family: "ArabicFontMedium";
}
.article-ar ul li {
  
  cursor:auto;
}


@media all and (max-width:940px){
  .privacy-section{
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .article{
    padding-bottom: 2rem;
  }
  
  .article h2{
    text-align: center;
    color: #335A79;
    font-size: 16.8px;
    padding-bottom: 1rem;
    font-family: "EngMedium";
  }
  
  .article p {
    line-height: 1.7rem;
    color: #00121F;
    font-size: 12px;
    text-align: left;
    font-family: "EngMedium";
  }
  .article ul {
    line-height: 1.7rem;
    color: #00121F;
    font-size: 12px;
    font-family: "EngMedium";
  }
  .article ul li {
    
    cursor:auto;
  }
  
  .blue-p{
    color: #6CC4DC!important;
    text-decoration: underline;
  }
  
  .article-ar{
    padding-bottom: 3rem;
  }
  
  .article-ar h2{
    text-align: center;
    color: #335A79;
    font-size: 20px;
    padding-bottom: 1rem;
    font-family: "ArabicFontMedium";
  }
  
  .article-ar p {
    line-height: 1.7rem;
    color: #00121F;
    font-size: 12px;
    text-align: right;
    font-family: "ArabicFontMedium";
  }
  .article-ar ul {
    line-height: 1.7rem;
    color: #00121F;
    text-align: right;
    font-size: 12px;
    font-family: "ArabicFontMedium";
  }
  .article-ar ul li {
    
    cursor:auto;
  }
}


/* GalleryPage */


.grid-nav {
  display: grid;
  gap: 0px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  padding-top: 2rem;
}

.grid-nav div{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.image-1 {
  background-image: url(./assets/Gellary/2.jpg);
}

.image-2 {
  background-image: url(./assets/Gellary/4.jpg);
}

.image-3 {
  background-image: url(./assets/Gellary/5.jpg);
} 

.image-4 {
  background-image: url(./assets/Gellary/6.jpg); 
   position: relative;
}

.image-5 {
   background-image: url(./assets/2.jpg); 
}

.image-6 {
   background-image: url(./assets/Gellary/7.jpg); 
   position: relative; 
}

.image-7 {
   background-image: url(./assets/Gellary/3.jpg); 
}

.image-8 {
   background-image: url(./assets/Gellary/4.jpg); 
}

.image-9 {
   background-image: url(./assets/Gellary/5.jpg); 
}

.image-10 {
   background-image: url(./assets/Gellary/6.jpg); 
}

.image-11 {
   background-image: url(./assets/Gellary/1.jpg); 
   position: relative; 
}

.image-12 {
   background-image: url(./assets/Gellary/7.jpg); 
}

.play-overlay {
  position: relative;
  overflow: hidden; 
}

.play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  cursor: pointer;
}
@media all and (min-width: 2100px){
  .grid-nav {
    display: grid;
    gap: 0px;
    grid-template-columns: repeat(auto-fit, minmax(415px, 1fr));
    padding-top: 2rem;
  }
}

@media all and (min-width: 1500px) and (max-width:1800px){
  .grid-nav {
    display: grid;
    gap: 0px;
    grid-template-columns: repeat(auto-fit, minmax(361px, 1fr));
    padding-top: 2rem;
  }
}

@media all and (max-width: 600px){
  .grid-nav {
    gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
  }
  .grid-nav div{
    border-radius: 10px;
  }
  .grid-nav div:nth-child(2),
  .grid-nav div:nth-child(3),
  .grid-nav div:nth-child(5),
  .grid-nav div:nth-child(7),
  .grid-nav div:nth-child(8)
  {
    display: none;
  }
  video {
    width: 100%;
        height: auto;
        object-fit: cover;
  }
}

.scroll-margin-top {
  scroll-margin-top: 120px; 
}
video {
  width: 100%;
      height: auto;
      object-fit: cover;
}